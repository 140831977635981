<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      class="staff-create-wrapper"
  >

    <p><strong>EMPLOYEE PROFILE:</strong> This is where details are entered for each practice <strong>employee*</strong> (including partners or owners in the business) whose work is:</p>

    <p>‘Relevant to compliance with the 2017 Regulations; or is otherwise capable of contributing to the identification and mitigation of the risks of MLTF to which the business is subject, or to the prevention or detection of MLTF in relation to the business.’</p>

    <p>*Note: A separate profile is required for Agents or Sub-contractors who are not ‘employees’ but provide relevant services to the Practice</p>

    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="First Name"
              label-for="staff-first-name"
              class="mb-2"
          >
            <b-form-input
                id="staff-first-name"
                v-model="staff.firstName"
                @change="saveStaffProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Last Name"
              label-for="staff-last-name"
              class="mb-2"
          >
            <b-form-input
                id="staff-last-name"
                v-model="staff.lastName"
                @change="saveStaffProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <label>Staff Member Role Attributes: select all that apply</label>
          <b-form-group>
            <b-row>
              <b-col md="6">
                <b-form-checkbox
                    v-model="staff.roles"
                    id="sole-practitioner"
                    name="staff_roles"
                    value="Sole Practitioner"
                    @change="saveStaffProgress"
                >
                  Sole Practitioner
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="staff.roles"
                    id="owner"
                    name="staff_roles"
                    value="Owner"
                    @change="saveStaffProgress"
                >
                  Owner
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="staff.roles"
                    id="bookkeeper"
                    name="staff_roles"
                    value="Bookkeeper"
                    @change="saveStaffProgress"
                >
                  Bookkeeper
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="staff.roles"
                    id="accountant"
                    name="staff_roles"
                    value="Accountant"
                    @change="saveStaffProgress"
                >
                  Accountant
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="staff.roles"
                    id="technician"
                    name="staff_roles"
                    value="Technician"
                    @change="saveStaffProgress"
                >
                  Technician
                </b-form-checkbox>

              </b-col>
              <b-col md="6">
                <b-form-checkbox
                    v-model="staff.roles"
                    id="account-manager"
                    name="staff_roles"
                    value="Account Manager"
                    @change="saveStaffProgress"
                >
                  Account Manager
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="staff.roles"
                    id="senior"
                    name="staff_roles"
                    value="Senior"
                    @change="saveStaffProgress"
                >
                  Senior
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="staff.roles"
                    id="junior"
                    name="staff_roles"
                    value="Junior"
                    @change="saveStaffProgress"
                >
                  Junior
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="staff.roles"
                    id="trainee"
                    name="staff_roles"
                    value="Trainee"
                    @change="saveStaffProgress"
                >
                  Trainee
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-group>

        </b-col>
      </b-row>

        <risk-question-block :system-form-id="3" :form="form" :answers="staff.answers" v-on:updateProgressReturn="updateProgressListener"
                             v-on:updateRiskReturn="updateRiskListener" v-on:getAnswersReturn="getAnswersListener"
                             v-on:updateFilesReturn="updateFilesListener"></risk-question-block>

      <b-row>
        <b-col
            cols="8"
            class="mt-50"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="saveStaff(false)"
          >
            <span v-if="!savingStaff">Save Changes</span>
            <span v-if="savingStaff"><b-spinner small /> Saving...</span>
          </b-button>
          <b-button
              :disabled="!canComplete || completed"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="!completed ? 'success' : 'flat-success'"
              class="mr-1"
              @click="saveStaff(true)"
          >
            <span class="align-middle" v-if="!completed && !savingStaff">Complete</span>
            <span class="align-middle" v-if="completed &&  !savingStaff"><feather-icon icon="CheckIcon"/> Completed</span>
            <span v-if="savingStaff"><b-spinner small /> Saving...</span>
          </b-button>
        </b-col>
        <b-col
            cols="4"
            class="mt-50 text-right"
        >
          <sup>Last updated: {{ staff.lastUpdated }}</sup>
        </b-col>
      </b-row>
    </b-form>
    <!--form-->

    <h3>Staff Risk: {{ staffRisk }}</h3>
  </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import staffService from "@core/services/staff/useStaff";
import RiskQuestionBlock from "../../blocks/RiskQuestionBlock";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BFormCheckbox,
    BSpinner,
    BOverlay,
    RiskQuestionBlock,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mounted() {
    if ( this.$route.params.id ){
      this.staff.id = this.$route.params.id;
    }
  },
  data() {
    return {
      staff: {
        id: 0,
        firstName: "",
        lastName: "",
        roles: [],
        lastUpdated: "",
        answers: []
      },
      form: {},
      riskQuestions: [],
      roles: ['Sole Practitioner', 'Owner', 'Bookkeeper', 'Accountant', 'Technician', 'Account Manager', 'Senior', 'Junior', 'Trainee'],
      staffRisk: 0,
      canComplete: false,
      completed: false,
      savingStaff: false,
      pageLoaded: false,
    }
  },
  methods: {
    getAnswersListener(value) {
      this.riskQuestions = value;
      this.getStaff();
    },
    getStaff() {
      if ( this.$route.params.id ){
        staffService.getStaff({
          id: this.staff.id
        }).then(response => {
          this.staff = response.data;
          this.canComplete = this.staff.isComplete;
          this.completed = this.staff.completed;
          this.staffRisk = this.staff.risk;
          this.pageLoaded = true;
          let that = this;

          if ( this.staff.answers.length > 0 ){
            this.staff.answers.forEach(function (answer) {
              that.$set(that.form, answer.questionLabel, answer)
            });
          }
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }else{
        this.pageLoaded = true;
      }
    },
    saveStaffProgress(){
        let userData = JSON.parse(localStorage.getItem('userData'));

        this.staff.practiceId = userData.practiceId;

        if (this.staff.id) {
          staffService.updateStaff({
            staff: this.staff,
            complete: false,
            id: this.staff.id
          }).then(response => {
            this.canComplete = response.data.complete;
          }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
        } else {
          staffService.createStaff({
            staff: this.staff,
            complete: false,
          }).then(response => {
            this.staff.id = response.data.id;
          }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
        }
    },
    saveStaff(complete) {
      this.savingStaff = true;
        let userData = JSON.parse(localStorage.getItem('userData'));

        this.staff.practiceId = userData.practiceId;

        if (this.$route.params.id) {
          staffService.updateStaff({
            staff: this.staff,
            complete: complete,
            id: this.staff.id
          }).then(response => {
            if ( response.data.errors ){
              this.showErrorMessage(response.data.errors);
            }else{
              this.showSuccessMessage("You have successfully updated this staff");
              if ( complete && !this.completed ){
                this.completed = true;
              }

              window.location.href = '/staff/' + this.staff.id;
            }
            this.savingStaff = false;
          }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
        } else {
          staffService.createStaff({
            staff: this.staff,
            complete: complete,
          }).then(response => {
            if ( response.data.errors ){
              this.showErrorMessage("Something went wrong!");
            }else {
              this.showSuccessMessage("You have successfully created this staff");

              this.staff.id = response.data.id;
              window.location.href = '/staff/' + this.staff.id;
            }
            this.savingStaff = false;
          }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
        }
    },
    updateProgressListener(value) {
      this.staff.answers = value;
      this.saveStaffProgress();
    },
    updateFilesListener(value) {
      this.files = value;
    },
    updateRiskListener(value) {
      this.staffRisk = value;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
